// 最外层盒子样式
%out-box {
  // width: (1920rem / 100);
  position: relative;
}

// 1920设计稿，所有的值除以30；

// 内层盒子
%inside-box {
  width: (1200rem / 100);
  margin: 0 auto;
}

.culture-container {
  @extend %out-box;
  background: url("https://image.bookgo.com.cn/%20webculture/jm/bg/bg_body.jpg");
  padding-bottom: 1.6rem;
  .culture-content {
    @extend %inside-box;
    padding-top: (38rem / 100);

    // 顶部
    .culture-switch-type {
      display: flex;
      margin-bottom: (12rem / 100);
      margin-top: 0.2rem;

      &-title {
        font-size: (16rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #761f1e;
        line-height: (32rem / 100);
        margin-right: (10rem / 100);
      }

      &-content {
        display: flex;
        flex-wrap: wrap;

        &-li {
          min-width: (100rem / 100);
          height: (32rem / 100);
          margin: 0 (30rem / 100) (12rem / 100) 0;
          background: url("https://image.bookgo.com.cn/webculture/jm/normal_type_li.png")
            no-repeat;
          background-size: 100% 100%;
          text-align: center;
          line-height: (32rem / 100);
          font-size: (16rem / 100);
          font-family: PingFangSC-Regular, PingFang SC;
          color: #273143;
          padding-left: 0.2rem;
          padding-right: 0.2rem;
          cursor: pointer;
        }
        .imgType {
          background: url("https://image.bookgo.com.cn/webculture/jm/normal_type_li.png")
          no-repeat;
          background-size: 100% 100%;
        }
        &-li-active {
          background: url("https://image.bookgo.com.cn/webculture/jm/activity_type_li.png")
            no-repeat;
          background-size: 100% 100%;
          color: #ffffff;
          font-weight: 500;
        }
      }
    }

    // 列表
    .culture-info {
      @extend %inside-box;
      margin: 0 auto (60rem / 100);

    }
  }
}
@mixin text-overflow($width: 100%) {
  width: $width;
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}
// 艺术培训
.culture-ul {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .culture-li {
    width: (380rem / 100);
    height: (390rem / 100);
    margin-right: (30rem / 100);
    background: url("https://image.bookgo.com.cn/webculture/jm/homepage/homepage-index-item-bg.png")
      no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: (20rem / 100);
    position: relative;
    margin-bottom: (30rem / 100);

    &-banner {
      position: relative;
      width: 100%;
      height: (200rem / 100);
      overflow: hidden;
      margin-bottom: (14rem / 100);
    }

    &-bottom {
      width: 100%;
      padding: (6rem / 100) (10rem / 100) 0;

      &-title {
        font-size: (18rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        line-height: (28rem / 100);
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: (11rem / 100);
      }

      &-des {
        width: 100%;
        margin-bottom: (10rem / 100);
        display: flex;
        align-items: center;

        .culture-li-bottom-icon {
          width: (24rem / 100);
          height: (24rem / 100);
          margin-right: (10rem / 100);
        }

        .culture-li-bottom-icon-title {
          @include text-overflow(
            $width: (
              340rem / 100,
            )
          );
          font-size: (16rem / 100);
          font-family: PingFangSC-Regular, PingFang SC;
          color: #273143;
          line-height: (16rem / 100);
        }
      }
    }
  }

  .culture-li-selected {
    width: 100%;
    height: (37rem / 100);
    position: absolute;
    left: 0;
    bottom: 0;
    display: none;
  }

  .culture-li:nth-of-type(3n) {
    margin-right: 0;
  }

  .culture-li:hover {
    .culture-li-selected {
      display: block;
    }
  }
}
.courselistState{
  position: absolute;
  left: 0rem;
  bottom: 0rem;
  padding: 0.07rem 0.15rem 0.06rem 0.11rem;
}
.coursecurrent2{
  background: url("https://image.bookgo.com.cn/%20webculture/jm/active-list-finished.png") no-repeat;
  background-size: 100% 100%;
}
.coursecurrent1{
  background: url("https://image.bookgo.com.cn/%20webculture/jm/active-list-jinxingzhong.png") no-repeat;
  background-size: 100% 100%;
}
.coursecurrent0{
  background: url("https://image.bookgo.com.cn/%20webculture/jm/active-list-baomingzhong.png") no-repeat;
  background-size: 100% 100%;
}
.courselistStateFont{
  font-size: 0.14rem;
  color: white;
  text-shadow: 0 1px 1px rgba($color: #000000, $alpha: 0.5);
}
